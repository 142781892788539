/*
Script to add a css class on link that redirect to the current page.
It's usefull to display the current page with an other style than the other in menus.

To use it, just add this attribute on the nearest parent that wrap all your menu links : data-current-class="current"
The value in the property will be the classname that will be add on your current links.

If you don't want to add the classname on the parent links of your current link, add this attribute : data-current-class-recursive="false"

Example :
<nav data-current-class="current">
    <ul>
        <li><a href="/">Page 1</a></li>
        <li><a href="/page-2">Page 2</a></li>
        <li>
            <a href="/page-3">Page 3</a>
            <ul>
                <li><a href="/sub-page-3">Sub-page 3</a></li>
            </ul>
        </li>
    </ul>
</nav>
*/

; (function () {
    'use strict';
    
    var currentUrl = location.pathname.toLowerCase();

    $('[data-current-class]').each(function () {
        var classname = $(this).data('current-class');
        
        var recursive = true;
        if (typeof $(this).data('current-class-recursive') !== 'undefined')
            recursive = $(this).data('current-class-recursive');

        $(this).find('a[href]').each(function () {
            if ($(this).attr('href').toLowerCase() === currentUrl) {
                $(this).addClass(classname);

                if (recursive) {
                    $(this).parentsUntil('[data-current-class]', 'li').each(function () {
                        $(this).children('a').addClass(classname);
                    });
                }
            }
        });
    });

}());
