/*
REQUIREMENTS:
	-Jquery
	-Jquery cookie
*/

(function ($) {
    $(document).ready(function () {
        if ($.cookie('cookieProweb') !== "1") {
            $('#CookieAlert').modal({
                keyboard: false,
                backdrop: "static"
            });
        }

        $('#CookieAlert').on('hidden.bs.modal', function () {
            $.cookie('cookieProweb', '1', { expires: 45 });
        });
    });
})(jQuery);