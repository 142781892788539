; (function ($) {
    'use strict';

    $(document).ready(function () {
        $('.hamburger').on('click', function () {
            if ($(this).hasClass('is-active')) {
                $('.hamburger').removeClass("is-active");
                $('body').removeClass('overflow-hidden');
            }
            else {
                $('.hamburger').addClass("is-active");
                $('body').addClass('overflow-hidden');
            }
        });
    });
}(jQuery));