; (function ($) {
    'use strict';
    $(document).ready(function () {
        goToTop();
    });

    $(window).scroll(function () {
        goToTop();
    });

    function goToTop() {
        if ($(document).scrollTop() >= 100)
            $('.go-to-top').css('opacity', 1);
        else
            $('.go-to-top').css('opacity', 0);
    }
}(jQuery));