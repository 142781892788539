window.$ = window.jQuery = $;

registerFunctionOnResize(function () {
    initViewHeight();
    initViewWidth();
});

function initViewHeight() {
    var vh = window.innerHeight * 0.01;
    setStyleVar('--vh', vh + 'px');
}

function initViewWidth() {
    var vw = window.innerWidth * 0.01;
    setStyleVar('--vw', vw + 'px');
}

function setStyleVar(name, value) {
    document.documentElement.style.setProperty(name, value);
}

function registerFunctionOnResize(callback) {
    $(document).ready(callback);

    var timer;
    $(window).resize(function () {
        clearTimeout(timer);
        timer = setTimeout(callback, 50);
    });
}

function sameHeightElements(element, setVar, name) {
    setVar = setVar ? setVar : false;
    name = name ? name : '';

    $(element).css('height', 'auto');
    var height = getBiggerHeightElements(element);

    if (setVar)
        setStyleVar(name, height + 'px');

    $(element).css('height', height);
}

function getBiggerHeightElements(element) {
    var height = 0;

    $(element).each(function () {
        if ($(this).outerHeight(true) > height)
            height = $(this).outerHeight(true);
    });

    return height;
}

function scrollToElement(elementTag) {
    $([document.documentElement, document.body]).animate({
        scrollTop: $(elementTag).offset().top
    }, 500);
}

function isMobile() {
    return navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false;
}

function showSearch() {
    var x = document.getElementById("searchBar");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
}

